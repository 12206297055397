@import '../../scss/fontPreset.scss';
@import '../../scss/size.scss';

.features {
    margin-top: 50px;
    .features__title {
        @include font_48_800(var(--textPrimaryContrast));
        max-width: 450px;
        text-align: center;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        & > span {
            position: relative;
            z-index: 2;
        }
        &:after {
            content: '';
            position: absolute;
            width: 168px;
            height: 168px;
            left: -30px;
            top: -20px;
            z-index: 1;
            background: url('../../svg/icons/icon-cup-bg-2.svg') no-repeat;
            background-size: contain;
        }

        @media (max-width: $sizeMobile) {
            @include font_32_800(var(--textPrimaryContrast));
            max-width: 300px;
            &:after {
                content: '';
                width: 110px;
                height: 110px;
                left: -26px;
            }
        }
    }

    .features__grid {
        margin-top: 60px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        text-align: center;
        gap: 60px;
        @media (max-width: $sizeMobile) {
            display: none;
        }

        &.mobile {
            display: none;
            @media (max-width: $sizeMobile) {
                display: grid;
                text-align: center;
                padding: 0 20px;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(3, 182px);
                gap: 15px;
            }
        }
    }

    .features__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .features__item_title {
            @include font_24_700(var(--textPrimaryContrast));
            max-width: 320px;
            margin-top: 40px;
        }
    }

    .features-mobile__item {
        background: rgba(#bdd9ff, 0.2);
        border-radius: 16px;
        display: grid;
        grid-template-rows: 1fr 30px;
        row-gap: 15px;
        padding: 20px 12px;

        .features-mobile__item_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            & > svg {
                width: 90px;
                height: 90px;
            }
        }

        .features-mobile__item_title {
            @include font_12_700(var(--textPrimaryContrast));
        }
    }

    .features__action {
        display: flex;
        justify-content: center;
        margin-top: 100px;
        position: relative;
        z-index: 2;
        @media (max-width: $sizeMobile) {
            margin-top: 40px;
        }

        .features__action_btn {
            @include font_20_700(var(--textPrimaryContrast));
            padding: 25px 0;
            background: var(--success);
            border-radius: 16px;
            max-width: 400px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            text-decoration: none;
            @media (max-width: $sizeMobile) {
                @include font_18_700(var(--textPrimaryContrast));
                max-width: 300px;
                padding: 18px 0;
            }
        }
    }
}

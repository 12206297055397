@import 'src/scss/fontPreset';
@import 'src/scss/size';
@import 'src/scss/reset';

.language-select {
    @include font_14_600(var(--textPrimaryContrast));
    margin-left: 10px;

    position: relative;

    .language-select__current {
        @include buttonReset;

        display: flex;
        flex-flow: row;
        align-items: center;
        padding: 8px;

        .language-select__img {
            filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
            border-radius: 4px;

            @media (min-width: $sizeTablet) {
                border-radius: 8px;
            }
            @media (max-width: $sizeMobile) {
                display: none;
            }
        }

        .language-select__title {
            margin-left: 4px;
        }

        @media (min-width: $sizeMobile) {
            padding: 0;
        }
    }

    .language-select__list {
        display: none;
        border-radius: 8px;

        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;

        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
    }

    .language-select__arrows {
        display: flex;
        align-items: center;
    }

    &.language-select____small {
        @include font_12_600();

        .language-select__current {
            .language-select__title {
                margin-right: 8px;
            }
        }
    }

    &.language-select____active {
        .language-select__list {
            display: block;
        }
    }
}

@import '../../scss/fontPreset.scss';
@import '../../scss/size.scss';

.contacts {
    margin-top: 140px;
    @media (max-width: $sizeMobile) {
        margin-top: 50px;
    }

    .contacts__top {
        text-align: center;
    }

    .contacts__bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
        @media (max-width: $sizeMobile) {
            flex-direction: column;
        }
    }

    .contacts-telegram__title {
        @include font_20_500(var(--textPrimaryContrast));
        @media (max-width: $sizeMobile) {
            @include font_14_500(var(--textPrimaryContrast));
            max-width: 255px;
            margin: 0 auto;
        }
    }

    .contacts-telegram__btn {
        @include font_18_600(var(--textPrimaryContrast));
        display: flex;
        align-items: center;
        justify-content: center;
        width: 320px;
        padding: 18px 0;
        border-radius: 16px;
        text-decoration: none;
        margin: 20px auto 0;
        background: linear-gradient(180deg, #37bbfe 0%, #007dbb 100%);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

        & > svg {
            width: 19px;
            height: 16px;
            margin-left: 5px;
        }

        @media (max-width: $sizeMobile) {
            width: 190px;
            @include font_14_600(var(--textPrimaryContrast));
        }
    }

    .contacts__title {
        @include font_36_700(var(--textPrimaryContrast));
        @media (max-width: $sizeMobile) {
            @include font_24_700(var(--textPrimaryContrast));
        }
    }

    .contacts__social {
        margin-left: 20px;
        @media (max-width: $sizeMobile) {
            margin-left: 0;
            margin-top: 16px;
        }
    }
}

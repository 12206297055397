@import '../../scss/fontPreset.scss';
@import '../../scss/size.scss';

.footer {
    display: flex;
    justify-content: center;
    padding: 35px 0 20px;

    .footer__link {
        @include font_20_400(var(--textPrimaryContrast));
        @media (max-width: $sizeMobile) {
            @include font_14_400(var(--textPrimaryContrast));
        }
    }
}

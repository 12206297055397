@import '../../scss/fontPreset.scss';
@import '../../scss/size.scss';

.header {
    display: flex;
    justify-content: center;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: background 0.2s ease-out;

    &.header__scrolled {
        background: rgba(#000, 0.3);
    }

    .header__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1320px;
        width: 100%;
    }

    .header__logo {
        width: 136px;
        height: 60px;

        @media (max-width: $sizeMobile) {
            height: 37px;
            & > svg {
                width: 83px;
                height: 37px;
            }
        }
    }

    .header__controls_signin {
        @include font_14_600(var(--textPrimaryContrast));
        background: transparent;
        padding: 18px;
        cursor: pointer;
        text-decoration: none;
        white-space: nowrap;

        @media (max-width: $sizeMobile) {
            @include font_12_600(var(--textPrimaryContrast));
            padding: 5px 10px;
            margin-right: 6px;
        }
    }

    .header__controls {
        display: flex;
        align-items: center;
    }

    .header__controls_signup {
        @include font_14_600(var(--textPrimaryContrast));
        background: transparent;
        padding: 18px;
        width: 180px;
        background: var(--secondary);
        border-radius: 8px;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: $sizeMobile) {
            @include font_12_600(var(--textPrimaryContrast));
            padding: 7px 8px;
            width: 94px;
            background: var(--success);
            box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
        }
    }
}

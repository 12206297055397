@import '../../scss/size.scss';

.payments {
    margin-top: 250px;
    position: relative;
    padding: 0 20px;
    @media (max-width: $sizeMobile) {
        margin-top: 100px;
    }

    .payments__block {
        padding: 37px 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--backgroundSecondary);
        border-radius: 100px;
        position: relative;
        z-index: 2;

        @media (max-width: $sizeMobile) {
            padding: 48px 20px;
            display: grid;
            gap: 40px 23px;
            grid-template-areas: 'bitcoin tether' 'mastercard visa' 'wire wire';
            justify-content: center;
            border-radius: 20px;
        }

        .payments__item {
            @media (max-width: $sizeMobile) {
                text-align: center;
                &.bitcoin {
                    grid-area: bitcoin;
                }
                &.tether {
                    grid-area: tether;
                }
                &.mastercard {
                    grid-area: mastercard;
                }
                &.visa {
                    grid-area: visa;
                }
                &.wire {
                    grid-area: wire;
                }
            }
        }
    }

    .payments__dollar_top {
        position: absolute;
        width: 172px;
        height: 173px;
        left: 30px;
        top: -105px;
        background: url('../../svg/icons/icon-banknote-bg-2.svg') no-repeat;
        background-size: contain;
        z-index: 1;
        @media (max-width: $sizeMobile) {
            width: 110px;
            height: 110px;
            left: -25px;
            top: 245px;
        }
    }
    .payments__dollar_bottom {
        position: absolute;
        width: 172px;
        height: 173px;
        left: 70px;
        bottom: -50px;
        background: url('../../svg/icons/icon-banknote-bg-1.svg') no-repeat;
        background-size: contain;
        z-index: 1;
        @media (max-width: $sizeMobile) {
            width: 130px;
            height: 130px;
            left: -9px;
            bottom: -70px;
        }
    }
    .payments__card {
        position: absolute;
        width: 315px;
        height: 205px;
        right: -70px;
        top: -138px;
        background: url('../../svg/icons/icon-card-coins-bg-1.svg') no-repeat;
        background-size: contain;
        z-index: 3;
        @media (max-width: $sizeMobile) {
            width: 245px;
            height: 115px;
            top: -64px;
        }
    }
}

@import '../../scss/size.scss';
@import '../../scss/var.scss';

.landing {
    @include variables;
    background: linear-gradient(148.5deg, var(--secondary) -2.18%, var(--primary) 54.27%);
    min-height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;

    .landing__container {
        max-width: 1320px;
        width: 100%;
        margin: 0 auto;
        padding-top: 100px;
        @media (max-width: $sizeMobile) {
            padding-top: 80px;
        }
    }

    .landing__conveyor {
        margin-top: 50px;
        @media (max-width: $sizeMobile) {
            margin-top: 20px;
        }
    }

    .landing__chip_left {
        position: absolute;
        top: 120px;
        width: 200px;
        height: 200px;
        left: 0;
        background: url('../../svg/icons/icon-chip-bg-1.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            top: 43px;
            width: 130px;
            height: 130px;
        }
    }
    .landing__card_left {
        position: absolute;
        top: 1120px;
        width: 327px;
        height: 449px;
        left: 0;
        background: url('../../svg/icons/icon-card-bg-1.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            width: 160px;
            height: 220px;
            top: 553px;
        }
    }
    .landing__points_left_first {
        position: absolute;
        top: 1430px;
        width: 391px;
        height: 155px;
        left: 0;
        background: url('../../svg/icons/icon-points-1.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            width: 190px;
            height: 75px;
            top: 665px;
        }
    }
    .landing__points_left_second {
        position: absolute;
        top: 2133px;
        width: 541px;
        height: 214px;
        left: 0;
        background: url('../../svg/icons/icon-points-3.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            display: none;
        }
    }
    .landing__coin_right {
        position: absolute;
        top: 80px;
        width: 330px;
        height: 330px;
        right: -165px;
        background: url('../../svg/icons/icon-coin-bg-2.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            top: 395px;
            width: 250px;
            height: 250px;
            right: -124px;
        }
    }
    .landing__card_right {
        position: absolute;
        top: 1420px;
        width: 430px;
        height: 592px;
        right: -100px;
        background: url('../../svg/icons/icon-card-bg-2.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            top: 875px;
            width: 168px;
            height: 300px;
            right: -20px;
        }
    }
    .landing__points_right_second {
        position: absolute;
        top: 2405px;
        width: 551px;
        height: 217px;
        right: -47px;
        background: url('../../svg/icons/icon-points-4.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            width: 185px;
            height: 74px;
            top: 930px;
            right: -60px;
        }
    }
    .landing__points_right_third {
        display: none;
        position: absolute;
        top: 2405px;
        width: 551px;
        height: 217px;
        right: -47px;
        background: url('../../svg/icons/icon-points-4.svg') no-repeat;
        background-size: contain;
        z-index: 0;
        @media (max-width: $sizeMobile) {
            display: block;
            width: 250px;
            height: 98px;
            top: 1775px;
            right: -116px;
        }
    }
    .landing__cup {
        position: absolute;
        top: 2307px;
        width: 280px;
        height: 280px;
        left: 47px;
        background: url('../../svg/icons/icon-cup-bg-1.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            left: -75px;
            top: 1722px;
            width: 220px;
            height: 220px;
        }
    }
}

@font-face {
    font-family: MontserratExtraBold;

    src: url(../assets/fonts/Montserrat-ExtraBold.ttf);
}

@font-face {
    font-family: MontserratBold;

    src: url(../assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
    font-family: MontserratSemiboldBold;

    src: url(../assets/fonts/Montserrat-SemiBold.ttf);
}

@font-face {
    font-family: MontserratMedium;

    src: url(../assets/fonts/Montserrat-Medium.ttf);
}

@font-face {
    font-family: MontserratRegular;

    src: url(../assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
    font-family: MontserratLight;

    src: url(../assets/fonts/Montserrat-Light.ttf);
}

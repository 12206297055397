@import '../../scss/fontPreset.scss';
@import '../../scss/size.scss';

.conveyor {
    width: 100%;
    height: 1500px;
    position: relative;

    @media (max-width: $sizeMobile) {
        height: 890px;
    }

    .conveyor__header {
        position: absolute;
        left: 0;
        top: 60px;
        max-width: 690px;
        z-index: 2;
        @media (max-width: $sizeMobile) {
            top: 0;
            left: 20px;
        }
    }

    .conveyor__title {
        @include font_72_800(var(--textPrimaryContrast));
        @media (max-width: $sizeMobile) {
            @include font_40_800(var(--textPrimaryContrast));
        }
    }
    .conveyor__subtitle {
        @include font_24_500(var(--textPrimaryContrast));
        margin-top: 20px;
        @media (max-width: $sizeMobile) {
            display: none;
        }

        &.mobile {
            display: none;
            @media (max-width: $sizeMobile) {
                @include font_20_500(var(--textPrimaryContrast));
                display: block;
                max-width: 250px;
            }
        }
    }

    .conveyor__about {
        position: absolute;
        right: 0;
        top: 830px;
        max-width: 620px;
        z-index: 2;
        text-align: right;
        @media (max-width: $sizeMobile) {
            top: 660px;
            text-align: center;
            padding: 0 20px;
        }

        .conveyor__about_title {
            @include font_48_800(var(--textPrimaryContrast));
            @media (max-width: $sizeMobile) {
                @include font_32_800(var(--textPrimaryContrast));
            }
        }

        .conveyor__about_subtitle {
            @include font_24_500(var(--textPrimaryContrast));
            margin-top: 26px;
            @media (max-width: $sizeMobile) {
                @include font_14_500(var(--textPrimaryContrast));
                margin-top: 20px;
            }
        }
    }

    .conveyor__dollars_big {
        position: absolute;
        top: -10px;
        left: 200px;
        width: 166px;
        height: 260px;
        background: url('../../svg/icons/icon-dollar-big.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            display: none;
        }
    }
    .conveyor__dollars_small {
        position: absolute;
        top: 120px;
        left: 550px;
        width: 92px;
        height: 144px;
        background: url('../../svg/icons/icon-dollar-small.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            top: -30px;
            left: 250px;
        }
    }
    .conveyor__chip {
        position: absolute;
        top: 360px;
        left: 93px;
        width: 228px;
        height: 228px;
        background: url('../../svg/icons/icon-chip-bg-2.svg') no-repeat;
        background-size: contain;
        z-index: 0;
        @media (max-width: $sizeMobile) {
            width: 90px;
            height: 90px;
            left: 30px;
            top: 120px;
        }
    }
    .conveyor__dollar {
        position: absolute;
        top: 550px;
        right: 30px;
        width: 200px;
        height: 256px;
        background: url('../../svg/icons/icon-dollar-3d.svg') no-repeat;
        background-size: contain;
        z-index: 0;
        animation: flipFlap 2.5s infinite ease-in-out;
        @media (max-width: $sizeMobile) {
            display: none;
        }
    }
    .conveyor__diamond {
        position: absolute;
        top: 1150px;
        right: 0;
        width: 244px;
        height: 216px;
        background: url('../../svg/icons/icon-brilliant.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            top: 612px;
            width: 76px;
            height: 75px;
        }
    }
    .conveyor__points {
        position: absolute;
        top: 1120px;
        width: 391px;
        height: 155px;
        right: -165px;
        background: url('../../svg/icons/icon-points-2.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            display: none;
        }
    }
    .conveyor__coin {
        position: absolute;
        top: 1370px;
        width: 174px;
        height: 144px;
        left: 400px;
        background: url('../../svg/icons/icon-coin-bg-1.svg') no-repeat;
        background-size: contain;
        @media (max-width: $sizeMobile) {
            top: 805px;
            left: 20px;
            width: 124px;
            height: 104px;
        }
    }
    .conveyor__bg {
        position: absolute;
        background: url('../../svg/icons/icon-conveyor.svg') no-repeat;
        z-index: 1;
        background-size: contain;
        width: 100%;
        height: 1501px;
        @media (max-width: $sizeMobile) {
            height: 510px;
            background-size: cover;
            top: 95px;
            right: -10px;
            max-width: 450px;
        }
    }
}

@keyframes flipFlap {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

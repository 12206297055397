@import './font';

@mixin fontSetter($color, $fontSize, $fontWeight) {
    font-size: $fontSize;
    font-weight: $fontWeight;

    color: $color;
}

@mixin font_72_800($color: inherit) {
    font-family: MontserratExtraBold, sans-serif;

    @include fontSetter($color, 72px, 800);
}

@mixin font_64_600($color: inherit) {
    font-family: MontserratSemiboldBold, sans-serif;

    @include fontSetter($color, 64px, 600);
}

@mixin font_48_800($color: inherit) {
    font-family: MontserratExtraBold, sans-serif;
    font-style: normal;

    @include fontSetter($color, 48px, 800);
}

@mixin font_48_700($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 48px, 700);
}
@mixin font_42_600($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 42px, 600);
}
@mixin font_36_800($color: inherit) {
    font-family: MontserratExtraBold, sans-serif;
    font-style: normal;
    text-transform: uppercase;

    @include fontSetter($color, 36px, 800);
}

@mixin font_40_800($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 40px, 800);
}

@mixin font_40_700($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 40px, 700);
}

@mixin font_36_700($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 36px, 700);
}
@mixin font_36_600($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 36px, 600);
}

@mixin font_34_800($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 34px, 800);
}

@mixin font_32_800($color: inherit) {
    font-family: MontserratExtraBold, sans-serif;

    @include fontSetter($color, 32px, 800);
}

@mixin font_30_800($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 30px, 800);
}

@mixin font_30_700($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 30px, 700);
}

@mixin font_28_800($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 28px, 800);
}

@mixin font_24_800($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 24px, 800);
}

@mixin font_24_700($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 24px, 700);
}

@mixin font_24_600($color: inherit) {
    font-family: MontserratSemiboldBold, sans-serif;

    @include fontSetter($color, 24px, 600);
}

@mixin font_24_500($color: inherit) {
    font-family: MontserratMedium, sans-serif;

    @include fontSetter($color, 24px, 500);
}

@mixin font_22_800($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 22px, 800);
}

@mixin font_22_700($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 22px, 700);
}

@mixin font_22_600($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 22px, 600);
}

@mixin font_22_400($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 22px, 400);
}

@mixin font_20_800($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 20px, 800);
}
@mixin font_20_700($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 20px, 700);
}
@mixin font_20_500($color: inherit) {
    font-family: MontserratMedium, sans-serif;

    @include fontSetter($color, 20px, 500);
}
@mixin font_20_400($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 20px, 400);
}

@mixin font_18_800($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 18px, 800);
}

@mixin font_18_700($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 18px, 700);
}
@mixin font_18_600($color: inherit) {
    font-family: MontserratSemiboldBold, sans-serif;

    @include fontSetter($color, 18px, 600);
}

@mixin font_18_400($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 18px, 400);
}
@mixin font_16_400($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 16px, 400);
}

@mixin font_16_800($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 16px, 800);
}
@mixin font_16_600($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 16px, 600);
}
@mixin font_14_700($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 14px, 700);
}

@mixin font_14_600($color: inherit) {
    font-family: MontserratSemiboldBold, sans-serif;

    @include fontSetter($color, 14px, 600);
}

@mixin font_14_500($color: inherit) {
    font-family: MontserratMedium, sans-serif;

    @include fontSetter($color, 14px, 500);
}

@mixin font_14_400($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 14px, 400);
}

@mixin font_12_800($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 12px, 800);
}
@mixin font_12_700($color: inherit) {
    font-family: MontserratBold, sans-serif;

    @include fontSetter($color, 12px, 700);
}

@mixin font_12_600($color: inherit) {
    font-family: MontserratSemiboldBold, sans-serif;

    @include fontSetter($color, 12px, 600);
}

@mixin font_12_400($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 12px, 400);
}

@mixin font_11_800($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 11px, 800);
}

@mixin font_11_600($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 11px, 600);
}
@mixin font_11_400($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 11px, 400);
}
@mixin font_10_700($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 10px, 700);
}

@mixin font_10_600($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 10px, 600);
}

@mixin font_10_400($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 10px, 400);
}

@mixin font_9_700($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 9px, 700);
}

@mixin font_8_400($color: inherit) {
    font-family: MontserratRegular, sans-serif;

    @include fontSetter($color, 8px, 400);
}

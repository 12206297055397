@import 'src/scss/fontPreset';
@import 'src/scss/size';
@import 'src/scss/reset';

.button-select {
    @include buttonReset();

    display: flex;
    align-items: center;
    padding: 8px;
    background: rgba(#fff, 0.4);
    width: 100%;

    @media (max-width: $sizeMobile) {
        background: #fff;
    }

    .button-select__img {
        display: flex;
        align-items: center;
        margin-right: 4px;
        filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
        border-radius: 4px;

        @media (min-width: $sizeTablet) {
            border-radius: 8px;
        }
        @media (max-width: $sizeMobile) {
            display: none;
        }
    }

    .button-select__title {
        display: flex;
        align-items: center;
        color: var(--textPrimary);
    }

    &:hover {
        background-color: rgba(#fff, 0.3);
    }

    &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .button-select__title {
            color: var(--secondary);
        }
    }

    &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

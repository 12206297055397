@mixin variables {
    --background: #f3f3f6; // athensGray
    --backgroundSecondary: white;
    --backgroundAlt: #e77990;
    --backgroundAltActive: #fc4665;
    --borderFrame: #ecedef; // porcelain
    --error: #f31f1f; // pomegranate
    --imagedCard: white;
    --primary: #3959d9; // royalBlue
    --primaryLight: #c4cdf4; // moonRaker
    --secondary: #fc4665; // radicalRed
    --secondaryLight: #f4bde4;
    --secondaryOpacity10: #fc466510;
    --secondaryOpacity50: #fc466550;
    --success: #2eba4d; // chateauGreen
    --successOpacity10: #2eba4d10;
    --successOpacity50: #2eba4d50;
    --textPrimary: #3f4250; // brightGray
    --textPrimaryContrast: white;
    --textPrimaryOpacity30: #3f425030;
    --textSecondary: #9fa0a8; // santasGray
    --textSecondaryOpacity50: #9fa0a850;
    --warn: #fdb43c; // yellowOrange
    --warnOpacity10: #fdb43c10;
    --warnOpacity50: #fdb43c50;

    --buttonFontColor: var(--textPrimaryContrast);
    --buttonFontColorActive: var(--textPrimaryContrast);
    --buttonBackgroundColor: var(--primaryLight);
    --buttonBackgroundColorActive: var(--primary);
    --buttonBackgroundAlt: var(--backgroundAlt);
    --buttonBackgroundActive: var(--backgroundAltActive);

    --inputColorBackground: var(--backgroundSecondary);
}
